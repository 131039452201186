<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-flex d-lg-none mr-1"
                size="22"
                @click="mqShallShowLeftSidebar = true"
              />
            </div>
            <div class="view-options d-flex justify-content-start align-items-center ">

              <div
                v-if="$store.getters['notification/notificationsUnreadQty']"
                class="mr-1"
              >
                <b-overlay
                  :show="submittingReadAll"
                  rounded
                  opacity="0.6"
                  spinner-medium
                  spinner-type="grow"
                  spinner-variant="secondary"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="readAllNotifications"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('notification.mark_all_as_read') }}</span>
                  </b-button>
                </b-overlay>
              </div>
              <div>
                <b-overlay
                  :show="submittingFetchNotifications"
                  rounded
                  opacity="0.6"
                  spinner-medium
                  spinner-type="grow"
                  spinner-variant="primary"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="fetchFilteredNotifications"
                  >
                    <feather-icon
                      icon="RefreshCwIcon"
                      class="mr-50"
                    /><span class="align-middle">{{ $t('Update') }}</span>
                  </b-button>

                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="body-content-overlay" />
    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <span v-if="totalNotifications!==null">
            {{ $tc("notification.notifications_found", totalNotifications) }}
          </span>
          <span v-else>{{ $t('notification.notification_finder') }}</span>
        </b-col>
      </b-row>
    </div>
    <!-- Overlay -->
    <b-overlay
      :show="submittingFetchNotifications"
      rounded
      opacity="0.6"
      spinner-medium
      spinner-variant="primary"
      spinner-type="grow"
    >
      <!-- Notifications -->
      <section :class="itemView">
        <b-card
          v-for="notification in notifications"
          :key="notification.id"
          :bg-variant="isNotificationUnread(notification)?'light-primary':''"
          class="ecommerce-card no-image"
        >
          <a
            class="d-flex align-items-stretch justify-content-between"
            @click="handleNotificationClickAction(notification)"
          >
            <b-media>
              <template #aside>
                <b-avatar
                  :variant="getNotificationVariant(notification.type)"
                  class="mr-1"
                >
                  <feather-icon
                    :icon="getNotificationIcon(notification.type)"
                  />
                </b-avatar>
                <div class="align-self-center">
                  <span :class="isNotificationUnread(notification)?'font-weight-bolder':''">
                    {{ $t(getNotificationTitle(notification.type)) }}
                  </span>
                  <div><small>{{ getEventDate(notification) }}</small></div>
                </div>
              </template>

            </b-media>
            <div>
              <b-avatar
                v-if="isReferralsNotification(notification)"
                variant="danger"
              >
                <feather-icon
                  icon="LogInIcon"
                />
              </b-avatar>
            </div>
          </a>
        </b-card>
      </section>
      <!-- Pagination -->
      <section v-if="totalNotifications">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filters.page"
              :total-rows="totalNotifications"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
    </b-overlay>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BPagination,
  BOverlay,
  BAvatar,
  BButton,
  BMedia,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { computed, ref, watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { getPostStatusProperty, getUserAvatarText } from '@/mixins/functions'

import { formatDateTime } from '@/filters/dateTime'
import { useDebounceFn } from '@vueuse/core'
import { useUserUi } from '@/views/apps/user/useUser'
import store from '@/store'
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter'
import router from '@/router'
import LeftFilterSidebar from './NotificationListLeftFilterSidebar.vue'
import { useNotificationFiltersSortingAndPagination, useNotificationUi } from './useNotificationList'
import {
  manageNotificationActions, pushNotification, webNotification, notificationOptions,
} from './useNotification'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BPagination,
    BOverlay,
    BButton,
    BAvatar,
    BMedia,

    LeftFilterSidebar,
  },
  filters: {
    formatDateTime,
  },
  directives: {
    Ripple,
  },
  mixins: [getPostStatusProperty, getUserAvatarText],

  computed: {

  },
  created() {
    // GlobalEventEmitter.$on('newMessageReceived', response => {
    //   this.fetchFilteredNotifications()
    //   this.playSound()
    // })
    //
    // GlobalEventEmitter.$on('userPermissionsUpdated', () => {
    //   this.fetchFilteredNotifications()
    // })
  },
  methods: {
    // playSound() {
    //   return new Audio(require('@/assets/audio/notification-tone.wav')).play()
    // },
    getEventDate(notification) {
      const date = notification.data.decline_date
          || notification.data.create_date
          || notification.data.decline_date
          || notification.data.accept_date
          || notification.data.withdraw_date
          || notification.data.updated_date
          || notification.data.submit_date
      return date ? this.$options.filters.formatDateTime(date) : ''
    },
    handleNotificationClickAction(notification) {
      const redirectTo = this.getRequestRoute(notification)
      this.readNotification(notification)
      if (this.getReferralId(notification)) {
        return this.switchToUser(notification.data, this.getRequestRoute(notification)).then(() => {
        })
      }
      return router.push(redirectTo)
    },

    getNotificationIcon(type) {
      const obj = this.notificationTypes[type]
      return obj ? obj.icon : 'ToolIcon'
    },
    getNotificationVariant(type) {
      const obj = this.notificationTypes[type]
      return obj ? obj.variant : 'secondary'
    },
    getNotificationTitle(type) {
      const obj = this.notificationTypes[type]
      return obj ? obj.title : 'Title'
    },
  },
  setup() {
    const { getMessage } = pushNotification()
    getMessage()

    // const systemNotifications = [
    //   {
    //     title: 'New feature is available 🎉',
    //     subtitle: 'Try Service Road Map feature',
    //     type: 'light-success',
    //     icon: 'CheckIcon',
    //   },
    // ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    const {
      openEvent, openServiceRequest, getReferralId, readNotification, getRequestRoute,
    } = manageNotificationActions()
    const { switchToUser } = useUserUi()

    // const q = ref('')

    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useNotificationFiltersSortingAndPagination()

    const {
      itemView, itemViewOptions,
    } = useNotificationUi()

    const ability = defineAbilityForCurrentUser()

    const {
      submittingFetchNotifications,
      fetchNotifications,
      submittingReadAll,
      readAllNotifications,
      totalNotifications,
      isNotificationUnread,
      isReferralsNotification,
    } = webNotification()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const { notificationTypes } = notificationOptions()

    // Wrapper Function for `fetchNotifications` which can be triggered initially and upon changes of filters
    const fetchFilteredNotifications = () => {
      const args = {
        page: filters.value.page,
        per_page: filters.value.perPage,
        order: filters.value.order,
        unread: filters.value.unread,
      }

      fetchNotifications(args)
    }

    fetchFilteredNotifications()

    watch([filters, sortBy], () => {
      fetchFilteredNotifications()
    }, {
      deep: true,
    })

    const notifications = computed(() => {
      let { notifications } = store.state.notification
      if (filters.value.unread === 'unread') {
        notifications = notifications.filter(notification => (isNotificationUnread(notification) ? notification : false))
        totalNotifications.value = store.getters['notification/notificationsUnreadQty']
      }
      return notifications
    })

    // const onSearchDebounced = useDebounceFn(query => {
    //   if (!query || query.length > 2) {
    //     fetchFilteredNotifications()
    //   }
    // }, 500)
    //
    // watch(q, () => {
    //   onSearchDebounced(q.value)
    // })
    return {
      notifications,
      filters,
      // q,
      filterOptions,
      sortBy,
      sortByOptions,
      // onSearchDebounced,

      itemView,
      itemViewOptions,

      totalNotifications,
      submittingFetchNotifications,
      fetchNotifications,
      submittingReadAll,
      readAllNotifications,
      readNotification,
      isNotificationUnread,
      isReferralsNotification,
      perfectScrollbarSettings,
      openEvent,
      openServiceRequest,
      fetchFilteredNotifications,
      notificationTypes,
      switchToUser,
      getReferralId,
      getRequestRoute,
      ability,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>

.author{
  bottom: -1px!important;
  right:0;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

[dir] .ecommerce-application .grid-view .ecommerce-card .item-img {
  padding-top: 0;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
min-height: 15.85rem;
display: block;
align-items: center;
}
.card-img-top {
max-height: 20em;
object-fit: cover;
}
.dark-layout .ecommerce-application .ecommerce-card .btn-light {
background-color: #242B3D !important;
}

.ecommerce-application .grid-view {
  grid-template-columns: none;
}

.link{
cursor: pointer;
}
.ecommerce-application .list-view .ecommerce-card .card-body {
border-right: none;
}
.ecommerce-application .list-view .ecommerce-card{
  margin-bottom: 1rem;
  grid-template-columns: none;
}

@media (min-width: 767.98px) {
.ecommerce-application .list-view .ecommerce-card.no-image,
.ecommerce-application .grid-view{
  //grid-template-columns: 1fr 1fr;
}

}
</style>
